import React from "react"
import { Link } from 'gatsby'

import Layout from "../../components/layout"
import Spacer from "../../components/spacer"
import Tooltip from "../../components/tooltip"

const Page = () => (
  <Layout title="About us" keywords={[`rail api`, `train ticket`, `api`]}>
    <content>
      <h1>About Hazardous Frog</h1>

      <p>We are a software engineering company. We have specialised in building complex applications that most companies tend
      to shy away from. A significant amount of our work has been in <strong>project rescue</strong>, where we have taken over 
      an existing project from some other software company when they have failed to complete or failed to deliver what was required.</p>

      <blockquote>
        <span>Integrating rail is quicker and easier with Hazardous Frog's API</span>
        <cite>Many software developers</cite>
      </blockquote>

      <p>It was one such project rescue commission that brought us into the rail development business. A very experienced and capable 
      company dropped out of the contract citing inadequate experience and knowledge to complete the project. We stepped in to complete
      the project and launched the first Web <Tooltip>TIS</Tooltip> for <a href="www.raileasy.co.uk">Raileasy</a>.</p>

      <p>We learned a lot from completing that project and from maintaining the project. Several years later we were commission to build
      a new <Tooltip>TIS</Tooltip> which would provide to be the UK's first rail <Tooltip>TIS</Tooltip> with a full 
      featured <Tooltip>API</Tooltip> completely separate to any display application.</p> 

      <p>Raileasy has used this to build a successful
      affiliate network. The concept of an <Tooltip>API</Tooltip> based <Tooltip>TIS</Tooltip> was quickly copied and now 
      most <Tooltip>TIS</Tooltip> offer this facility. Many unique features from our <Tooltip>API</Tooltip> have been copied. We have 
      had a great deal of feedback as a result of supporting that <Tooltip>API</Tooltip> from developers who have looked at all 
      available <Tooltip>TIS</Tooltip> and their <Tooltip>API</Tooltip>. The consensus was from the outset and continue to be that
      ours is the most powerful and easy to use. We are extremely proud of that given that we are a small engineering company completing
      against companies like The Tranline and ATOS who have hundreds of developers at their disposal.</p>

    </content>

    <Spacer>
      <p>Our new API</p>
    </Spacer>

    <content>
      <p>As you would expect, we have learned a lot along that way and in hindsight would have built certain features differently. We have 
      made very effective use of that hindsight in order to build <Link to="/smartix/" className="printLink">smartix</Link>. It would
      have been very easy to just built a better version of what we had built before. Indeed, we started down that path twice before 
      abandoning those projects.</p>

      <blockquote>
        <span><Link to="/smartix/" className="printLink">smartix</Link> has been designed by engineers, for engineers in order to
        provide the best possible product for our customers and the best possible service for their customers.</span>
      </blockquote>

      <p>In our minds, <Link to="/smartix/" className="printLink">smartix</Link> should not just be an incremental improvement on
      what we had build before. It had to be a complete rethink, and re-architecture, from the ground up to be <strong>much</strong> easier 
      to use, in fact, as easy to use at it could possibly be, faster, more reliable, easier to maintain and extend, more cost effective 
      to operate (so that our fees could be lower). It had to be groundbreaking...</p>

      <p>That determination to keep going back to the drawing board until our design achieved all of those goals has resulted 
      in <Link to="/smartix/" className="printLink">smartix</Link> being the first <Tooltip>TIS</Tooltip> that allows you to choose any
      journey planner. The first <Tooltip>TIS</Tooltip> where you do not need to maintain <strong>any</strong> rail data in your
      customer data. The first <Tooltip>TIS</Tooltip> with virtually unlimited, instant scaling in response to demand. The 
      first <Tooltip>TIS</Tooltip> to offer concurrent versioning. Probably the first application to offer anything as powerful as 
      our <Link to="/smartix/architecture/#error-tracking">automated error tracking system</Link> for unparalleled reliability.
      </p>

      <p><Link to="/smartix/" className="printLink">smartix</Link> is the first <Tooltip>TIS</Tooltip> not to use <Tooltip>NRS</Tooltip> for 
      seat selection, our own <Link to="/smartix/reservations/#advanced-selection">seat selection system</Link> providing a far superior
      service for your customers.</p>
    </content>

    <Spacer>
      <p>Prior Art</p>
    </Spacer>

    <content>

      <p>Hazardous Frog's ability to tackle the most complex of projects is a result of the training and prior experience of its staff. 
      We have contributed to avionics software for the military aerospace industry, we have developed mobile phone network systems, 
      designed insurance quotations engines, content management systems, airline booking systems and accountancy applications to name 
      but a few. </p>

    </content>
  </Layout>
)

export default Page
